.a-icon-search, .a-icon-close, .a-icon-menu {
  min-width: 24px;
  min-height: 24px;
  background-size: 24px 24px;
  &:before {
    content: '' !important;
  }
}

.a-icon-search {
  background: url("../images/icon_search.svg") no-repeat center;
  &:before {
    content: '' !important;
  }
}

.a-icon-close {
  background: url("../images/icon_close.svg") no-repeat center;
  &:before {
    content: '' !important;
  }
}

.a-icon-menu {
  background: url("../images/icon_menu.svg") no-repeat center;
  &:before {
    content: '' !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .a-icon {
    &:before {
      content: '' !important;
    }
  }
}
