.unsupported-browser {
    position: fixed;
    width: 100%;
    left: 50vw;
    transform: translate(-50%, 0px);
    padding: 20px;
    color: #fff;
    z-index: 1;
    background-color: #00205B;
    display: none;
    bottom: 0px;

    .chrome {
      color: #1aa260;
    }

    .closeunsupported {
      font-weight: 700;
      color: #fff;
      font-size: 1.2em;
      padding: 0 0 0 20px;
    }
}
