.menu-anchor {
  display: none;

  @include tablet() {
    display: block;
    text-align: center;
    margin: calc($column-gutter / 2) 0;
  }
}

.menu-anchor__list {
  margin: 0;

  .menu-anchor__item::before,
  &::after {
    content: " ";
    padding: 0 8px;
    border-top: 1px solid #888;
    position: relative;
    top: 10px;
    right: 12px;
  }

  &::after {
    left: -12px;
  }
}

.menu-anchor__item {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  text-transform: uppercase;
}
