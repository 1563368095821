$grid-brand__cell-width--desktop: 20%;
$grid-brand__cell-width--tablet: 25%;
$grid-brand__cell-width--mobile: calc(100% / 3);
$grid-brand__cell-margin--desktop: 2rem;
$grid-brand__cell-margin--tablet: 1.5rem;
$grid-brand__cell-margin--mobile: 1rem;
$grid-brand__image-height: 150px; // scale: 'height(150)' - grind-brand.es6

.grid-brand {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin-top: 1rem;

    @include desktop() {
      margin: 2rem 5rem;
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $grid-brand__cell-width--mobile;
    min-height: 125px;
    margin: 0;

    @include tablet() {
      width: $grid-brand__cell-width--tablet;
      height: calc(150px + $grid-brand__cell-margin--tablet);
    }

    @include desktop() {
      width: $grid-brand__cell-width--desktop;
      height: calc(150px + $grid-brand__cell-margin--desktop);
      transition: all 200ms;
      border-radius: 0.5rem;

      &:hover { transform: scale(1.15); }
    }

    a {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      max-width: $grid-brand__image-height;
      max-height: $grid-brand__image-height;
      object-fit: contain;
    }
  }

  &__logo-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1rem $grid-brand__cell-margin--mobile;

    @include tablet() { padding: 1rem $grid-brand__cell-margin--tablet; }
    @include desktop() { padding: 1rem $grid-brand__cell-margin--desktop; }
  }
}
