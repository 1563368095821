.nutrient-table {
  border: none;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;

  tr:nth-child(even) {
    background-color: $secondary-background-color;
  }

  td {
    border: 1px solid rgba(0,0,0,.15);
    color: #222;
    font-size: .875rem;
    padding: .5625rem .625rem;
    text-align: left;
  }
}

.nutrient-table__cell {
  vertical-align: top;
  border: none;
  padding: 0;
}

.nutrient-table__text--indent {
  padding-left: 0.5em;
}

.nutrient-table__value {
  text-align: right;
}
