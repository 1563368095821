/*
 * custom variables
 */

// logo
$logo: url('../images/static/Leverant_Secondary_Green.svg');
$logo-secondary: url('../images/static/Leverant_Secondary_Offwhite.svg'); // footer may need other colors

// logo dimentions; height & width
$logo-size-big: 80px 160px;
$logo-size-normal: 70px 70px;
$logo-size-small: 60px 60px;

/*
 * existing variables
 * - see "xp-lib-brand/.../assets/styles/_variables.scss" for more
 */

 // color variations
$primary-color-green: #00411e;
$primary-color-orange: #fbbc50;
$primary-color-beige: #f8ecd5;
$secondary-color-red: #e86f4f;
$secondary-color-turquoise: #96d5d6;
$secondary-color-blue: #32658c;
$secondary-color-lightgreen: #448d51;

// colors main
$primary-color: $primary-color-green;
$secondary-color: $primary-color-beige;
$tertiary-color: #eee;

// logo - header
$logo-header: $logo;
$logo-header-size-desktop: $logo-size-big;
$logo-header-size-tablet: $logo-size-normal;
$logo-header-size-mobile: $logo-size-small;
// logo - footer
$logo-footer: $logo-secondary;
$logo-footer-size: $logo-size-big;

// footer
$footer-border-color: $secondary-color-blue;
$footer-border: $secondary-color-blue 1px solid;
