.product-image--new {
  overflow: visible;

  &::before {
    position: absolute;
    top: 5%;
    right: 5%;
    content: "";
    background:  url('../images/badge_ny_small.svg');
    background-repeat: no-repeat;
    display: block;
    height: 30%;
    width: 30%;
    z-index: 100;
  }
}
