@import "../utils/mediaquery";

.content {
  .author,
  .published-date {
    text-align: $content--title-align;
  }

  .content-meta {
    margin-bottom: $column-gutter;
  }

  .preface,
  .html-content,
  section[data-portal-component-type='text'] {
    padding: 0 calc($column-gutter / 2);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    color: #00205B;

    @include tablet() {
      max-width: 75%;
    }
  }
}
