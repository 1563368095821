.vacancy-message {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: center;

  span {
    display: inline-block;
    border: 1px dashed $secondary-color;
    border-radius: 5px;
    padding: 0.5rem 0.7rem;
    font-size: 0.8rem;
    line-height: 130%;
    color: #666;
  }
}

.vacancy-modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  &__fade-bg-color {
    // instead be backdrop-filter: brightness(0.8) but <body> not covering whole page
    background-color: #c2c2c2;
  }

  &__fade-bg-brightness {
    filter: brightness(0.8);
  }

  &__box {
    width: 100%;
    max-width: 42rem;
    height: auto;
    margin: 2rem;
    padding: 3rem;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 30px 0 rgba(100, 100, 111, 0.4);

    @include mobile {
      padding: 1.75rem;
      margin: 1rem;
    }

    h3 {
      font-size: 130%;
      margin-top: 0;
      width: calc(100% - 6rem); // (1rem padding + 2rem margin) * 2
    }
  }

  &__header {
    display: flex;
    flex-flow: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__close {
    position: relative;
    width: 25px;
    height: 30px;
    cursor: pointer;

    &::after {
      content: "\2A09"; // this will render the 'X'
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      font-size: 1.25rem;
      line-height: 100%;
      font-weight: 600;
      padding: 1rem;
      margin: -1rem;
      margin-bottom: 0;
    }
  }

  &__thank-you {
    display: none;
    margin-top: 2rem;
  }

  &__form {
    margin-top: 3rem;

    &--required { color: $secondary-color; }

    @include mobile {
      margin-top: 2rem;
    }

    input {
      box-sizing: border-box;
      width: 100%;
      margin: 0.5rem 0 1.25rem 0;
      padding: 0.7rem;
      border-radius: 3px;
      border: 1px solid #ccc;
    }

    .consent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      margin: 0.75rem 0.5rem 1.5rem;

      input {
        margin-right: 1rem;
        width: auto;
      }

      label {
        width: 100%;
        cursor: pointer;
      }
    }
  }
}

.vacancy-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  @include mobile {
    flex-direction: column;
  }
}

.vacancy-btn {
  cursor: pointer;
  width: 100%;
  padding: 0.9rem 1rem 1rem;
  margin: 1rem 1.5rem;
  text-align: center;
  text-decoration: none;
  line-height: 120%;
  border-radius: 5px;

  &:hover,
  &:focus-visible {
    transition: 150ms;
  }

  &--form {
    border: 2px solid $primary-color;
    background: #fff;
    color: $primary-color;
    margin-right: 0;
    margin-left: 0;

    &:hover,
    &:focus-visible {
      background: $primary-color;
      color: #fff;
    }
  }

  &--modal,
  &--custom {
    width: 50%;

    @include mobile {
      width: 100%;
      max-width: 300px;
    }
  }

  &--custom {
    border: 2px solid $primary-color;
    background: $primary-color;
    color: #fff;

    &:hover,
    &:focus-visible {
      background: #fff;
      color: $primary-color;
    }
  }

  &--modal {
    border: 2px solid $secondary-color;
    background: $secondary-color;
    color: #fff;

    &:hover,
    &:focus-visible {
      background: #fff;
      color: $secondary-color;
    }
  }

  &--standalone {
    margin-top: 0;
    margin-bottom: 0;
  }
}
