.m-card {
   &--no-image {
      padding-left: 20px !important;
   }

   &--featured {
      .m-card--right {
         flex-direction: row-reverse;
      }
   }

   &__logo {
      width: 80px;
      margin-bottom: 50px;

      img {
         min-height: unset !important;
      }
   }
}

.leverant .m-card--featured .m-card__content .a-btn--default,
.leverant .m-card--featured__content .a-btn--default {
   border-radius: 26px;
   background-color: transparent;
   color: #00411e;
   width: 130px;
   justify-content: center;
}
