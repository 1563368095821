@import 'normalize.css';

// framework
@import "variables";
@import "fonts";
@import "utils/mediaquery";
@import "utils/hidden";

// components
@import "components/author";
@import "components/copyright-holder";
@import "components/icon";
@import "components/product-image";
@import "components/nutrient-table";
@import "components/published-date";
@import "components/unsupported-browser";
@import "components/vacancy-modal";

// react
@import "react/productMap";

// parts
@import "parts/block-media-text";
@import "parts/content";
@import "parts/grid-brand";
@import "parts/iframe";
@import "parts/menu-anchor";
@import "parts/termly";
@import "parts/timeline";

// pages
@import "pages/error-page";
