// Breakpoints
$mobile: 768px !default;
$tablet: 1024px !default;
$desktop: 1280px !default;

@mixin larger-than($breakpoint) {
  @media all and (min-width: $breakpoint) { @content; }
}

@mixin smaller-than($breakpoint) {
  @media all and (max-width: ($breakpoint - 1px)) { @content; }
}

@mixin between($small, $big) {
  @media all and (max-width: ($big - 1px)) and (min-width: $small) { @content; }
}

// 1280px to Infinity px

@mixin desktop() {
  @media all and (min-width: $tablet) { @content; }
}

// 768px to 1279

@mixin tablet() {
  @media all and (min-width: $mobile) { @content; }
}

// 360px to 767px

@mixin mobile() {
  @media all and (max-width: ($mobile - 1px)) { @content; }
}
