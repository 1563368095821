@font-face {
  font-family: "Screamer";
  font-weight: 400;
  src:
    url("../fonts/screamer/FKScreamer-Regular.otf") format("opentype"),
    url("../fonts/screamer/FKScreamer-Regular.woff2") format("woff2"),
    url("../fonts/screamer/FKScreamer-Regular.woff") format("woff");
}

@font-face {
  font-family: "Screamer";
  font-weight: 500;
  src:
    url("../fonts/screamer/FKScreamer-Medium.otf") format("opentype"),
    url("../fonts/screamer/FKScreamer-Medium.woff2") format("woff2"),
    url("../fonts/screamer/FKScreamer-Medium.woff") format("woff");
}

@font-face {
  font-family: "Screamer";
  font-weight: 700;
  src:
    url("../fonts/screamer/FKScreamer-Bold.otf") format("opentype"),
    url("../fonts/screamer/FKScreamer-Bold.woff2") format("woff2"),
    url("../fonts/screamer/FKScreamer-Bold.woff") format("woff");
}

@font-face {
  font-family: "Screamer";
  font-weight: 900;
  src:
    url("../fonts/screamer/FKScreamer-Black.otf") format("opentype"),
    url("../fonts/screamer/FKScreamer-Black.woff2") format("woff2"),
    url("../fonts/screamer/FKScreamer-Black.woff") format("woff");
}
