@import '../utils/mediaquery';

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.productMap {
  width: 100%;
  padding-top: 2rem;
}

.product-map-container {
  height: 75vh;
  min-height: 225px;
  max-height: 500px;
  width: 100%;
  position: relative;

  .infowindow-heading {
    margin-top: 5px;
  }

  .loading-spinner {
    z-index: 2;
    position: absolute;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: -30px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border-left: 5px solid #98bbdc;
    border-bottom: 5px solid #98bbdc;
    border-right: 5px solid #fff0;
    border-top: 5px solid #fff0;
    animation: rotation 2s infinite linear;
  }

  &.instructions {
    text-align: center;
    background-position: center;
    padding-top: 12rem;
    position: relative;
    background-image: url('../images/bg_map.png');

    p {
      z-index: 1;
      font-size: 125%;
      margin: 0 auto 25px;
      width: 80%;
      background-color: White;
      border-radius: 10px;
      padding: 20px;

      @include desktop {
        width: 50%;
      }
    }

    .btn {
      display: inline-block;
      text-align: center;
      text-decoration: none;
      margin: 2px 0;
      border: solid 1px transparent;
      border-radius: 6px;
      padding: 0.5em 1em;
      color: #fff;
      background-color: #003e7e;
      cursor: pointer;
      font-size: 125%;

      &:hover {
        color: #003e7e;
        border-color: currentColor;
        background-color: white;
      }

      &:active {
        transform: translateY(1px);
        filter: saturate(150%);
      }
    }
  }
}

.product-map-container-marker {
  border-radius: 100%;
  background: #0074d9;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  z-index: 1;

  &.hover {
    background: red;
  }

  .label {
    position: absolute;
    top: -25px;
    left: 0;
    background: white;
    border-radius: 3px;
    width: 150px;
    padding: 3px;
    z-index: 3;
  }

  .pointer {
    position: absolute;
    background: white;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    top: -10px;
    left: 7px;
    z-index: 2;
  }
}
