.error-page {
  flex: 1 1 auto;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
}

.error-page__wrap {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  width: 65vw;
  height: 65vw;
  overflow: hidden;
  text-align: center;
  position: absolute;
  left: 2vw;
  top: 2vw;
  padding: 1vw;

  @include tablet() {
    top: 12vw;
    width: 35vw;
    height: 35vw;
  }
}

.error-page__error-404 {
  background-image: url('../images/cow.jpg');
}
