.color-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 23.7px 50px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.color-card {
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 30%;
    margin-bottom: 0;
  }
}

.section-color-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
}

.logo-container img {
  width: 80px; /* Adjust as needed */
  height: auto;
}

.title-container,
.url-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.ingress-container {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column; 
  justify-content: space-between;  

  @media (min-width: 1440px) {
    width: 100%;
  }
}


.a-btn.leverant {
  justify-content: flex-start ;
  margin: unset;
}

.a-btn-text{
  margin-left: unset !important;
  font-weight: 550 !important;
}

.a-btn--default .a-icon-arrow_right.leverant{
  padding-top: 1px !important;
}

.m-card__content-dark-green{
  background-color: #448d51 !important;
}