.u-sm-hidden {
  @include mobile() {
    display: none;
  }
}

.u-md-hidden {
  @include tablet() {
    display: none;
  }
}

.u-lg-hidden {
  @include desktop() {
    display: none;
  }
}
