@import "parts/color-card.scss";
@import "parts/list-person.scss";
@import "_variables.scss";

body {
	background: #fff;
}

.m-media {
	&__youtube {
		min-height: 400px;
	}
}

.main-container {
	background-color: $primary-color-beige;
}

.o-intro__heading {
	font-family: Screamer;
	font-size: 3rem;
}

.o-intro__preface {
	line-height: 100%;
}

.o-footer__logo:before {
	background-color: $primary-color-beige;
}

.o-footer__logo:after {
	background-color: $primary-color-beige;
}

.o-footer__shortcuts {
	border-top: 1px solid #f8ecd5;
}

.o-footer {
	color: #f8ecd5;
}

.o-footer__legal a {
	color: #f8ecd5;
}

.o-footer__component-title.t-heading {
	color: #96d5d6;
}

.o-footer__component-content a {
	color: #96d5d6;
}

.o-footer__shortcuts li a {
	color: #96d5d6;
}

.o-intro {
	&__link-container {
		&-green {
			background-color: $primary-color-green;

			.o-intro__heading, .o-intro__preface {
				color: $primary-color-beige;
			}
		}

		&-blue {
			background-color: $secondary-color-turquoise;
		}

		&-orange {
			background-color: $primary-color-orange;

			.o-intro__heading, .o-intro__preface {
				color: $primary-color-green;
			}
		}

		&-pink {
			background-color: $secondary-color-red;
		}
	}
}
