// Sari is used on "back-to-tine" and "menu", so should be included in brands

@font-face {
  font-family: Sari;
  src:
    url('../fonts/SariWeb.woff2') format('woff2'),
    url('../fonts/SariWeb.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: Sari;
  src:
    url('../fonts/SariWebMedium.woff2') format('woff2'),
    url('../fonts/SariWebMedium.woff') format('woff');
  font-display: swap;
  font-weight: bold;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Light.eot');
  src: url('../fonts/TelefonWeb-Light.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-Light.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-LightItalic.eot');
  src: url('../fonts/TelefonWeb-LightItalic.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-LightItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Blond.eot');
  src: url('../fonts/TelefonWeb-Blond.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-Blond.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Blond.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-BlondItalic.eot');
  src: url('../fonts/TelefonWeb-BlondItalic.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-BlondItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-BlondItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Normal.eot');
  src: url('../fonts/TelefonWeb-Normal.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-Normal.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Normal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-NormalItalic.eot');
  src: url('../fonts/TelefonWeb-NormalItalic.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-NormalItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-NormalItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Medium.eot');
  src: url('../fonts/TelefonWeb-Medium.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-Medium.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-MediumItalic.eot');
  src: url('../fonts/TelefonWeb-MediumItalic.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-MediumItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Bold.eot');
  src: url('../fonts/TelefonWeb-Bold.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-Bold.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-BoldItalic.eot');
  src: url('../fonts/TelefonWeb-BoldItalic.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-BoldItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Black.eot');
  src: url('../fonts/TelefonWeb-Black.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-Black.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-BlackItalic.eot');
  src: url('../fonts/TelefonWeb-BlackItalic.eot?#iefix') format('embedded-opentype');
  src: url('../fonts/TelefonWeb-BlackItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-BlackItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?eb16e9');
  src: url('../fonts/icomoon.eot?eb16e9#iefix') format('embedded-opentype');
  src: url('../fonts/icomoon.ttf?eb16e9') format('truetype'),
      url('../fonts/icomoon.woff?eb16e9') format('woff'),
      url('../fonts/icomoon.svg?eb16e9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-display: swap;
}
