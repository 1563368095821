$box-hover-color-red: #b80040;
$timeline-background-color: $primary-background-color;
$box-background-color: $secondary-background-color;

.timeline {
  position: relative;
}

.timeline__ruler {
  display: none;

  @include tablet() {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 100%;
    background: url("../images/ruler.png") top right repeat-y;
    z-index: 1;
  }
}

.timeline__list {
  background: $timeline-background-color;
  margin: 0;
  position: relative;
  list-style: none;
  padding: $column-gutter 0 0;
  overflow: hidden;

  @include tablet() {
    padding: $column-gutter;
  }
}

.timeline__event {
  width: 100%;
  list-style: none;
  transition: border-color;
  z-index: 0;
  position: relative;
  margin-bottom: $column-gutter;

  @include tablet() {
    border-top: 1px dashed #a59b94;
    margin-top: -5%;
    margin-bottom: $column-gutter;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &:hover {
    border-color: $box-hover-color-red;
  }

  &:nth-child(even) {
    padding-right: 5%;

    @include tablet() {
      padding-right: 0;
    }
  }

  &:nth-child(odd) {
    padding-left: 5%;
    z-index: 1;

    @include tablet() {
      margin-left: 50%;
      padding-left: 0;
    }
  }
}

.timeline__box {
  display: flex;
  padding: calc($column-gutter / 2);
  border: 1px solid $box-background-color;
  background: $box-background-color;
  transition: border-color ease-in-out;
  top: -1px;
  position: relative;

  @include tablet() {
    width: 45%;
  }

  &:hover {
    border-color: $box-hover-color-red;
  }
}

.timeline__media,
.timeline__text {
  flex-basis: 100%;
}

.timeline__media {
  overflow: hidden;
  display: block;
  transition: transform 0.4s;
  border-radius: 10px;
  margin-right: $column-gutter;
}
